
import { useRoute } from 'vue-router'
import { defineComponent, reactive, toRefs } from 'vue'
import Header from './components/Header.vue'

declare let globalConfig:any

export default defineComponent({
  components: {
    Header
  },
  setup () {
    const route = useRoute()
    const state = reactive({
      config: globalConfig,
      url: window.location.host,
      alias: route.params.alias
    })

    const stateAsRefs = toRefs(state)

    switch (state.alias) {
      // case 'about':
      //   document.title = '关于' + state.config.abbreviation + '—缩吧短网址'
      //   break
      case 'eula':
        document.title = '用户协议—缩吧短网址'
        break
      case 'privacy':
        document.title = '隐私政策—缩吧短网址'
        break
      case 'rules':
        document.title = '链接内容管理—缩吧短网址'
        break
    }

    return {
      ...stateAsRefs
    }
  }
})
