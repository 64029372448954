<template>
  <div id="pageMode">
    <Header />
    <!-- <div class="head">
      <div class="container">
        <div class="logo">
          <img src="/static/logo.png">
        </div>
        <div class="control">
          <div class="tag">
            <div class="link">
              <p>联系我们</p>
              <div class="box">
                .
              </div>
            </div>
          </div>
          <div class="user">
            <div class="btn">
              用户中心
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="body">
      <div class="top">
        <img src="https://cdn.link-hub.cn/dwz3.cn/static/stack/img/knowledge-1.jpg">
        <p
          v-if="alias == 'rules'"
        >
          链接内容管理规范
        </p>
        <!-- <p v-else-if="alias == 'about'">
          关于{{ config.abbreviation }}
        </p> -->
        <p v-else-if="alias == 'privacy'">
          隐私政策
        </p>
        <p v-else>
          用户协议
        </p>
      </div>
      <div class="bottom">
        <div
          v-if="alias == 'privacy'"
          class="container"
        >
          <p>本隐私政策描述了宿迁海内科技有限公司（以下简称“海内科技”）在您使用{{ config.name }}（以下简称“本网站”）以及{{ config.name }}所提供的服务、功能、内容或应用程序时对您的相关信息的收集、使用和披露的政策和程序。</p>

          <p>本网站可能通过以下途径获取您的信息：</p>

          <p>1. 当您注册本网站和服务时通过您在本网站的用户账户获取；</p>

          <p>2. 当您使用服务时；</p>

          <p>3. 通过第三方网站和服务获取，包括海内科技拥有的其他的应用和服务。</p>

          <p>您使用我们的服务即意味着您同意我们收集、传输、存储、披露您的信息以及将您的信息用作本《隐私政策》中描述的其他用途。您所提供的信息将可能被我们在中国或者其他国家传输、处理和访问。</p>

          <p><strong>本《隐私政策》包含哪些内容？</strong></p>

          <p>本《隐私政策》涵盖了对于您使用我们服务时本网站所收集的信息的处理，也涵盖了对于本网站商业伙伴或者海内科技其他的应用和服务分享的您的信息的处理。</p>

          <p>本《隐私政策》不适用于不属于海内科技拥有和控制的其他企业的做法，包括您能通过本网站访问的其他公司的网站、服务和应用（第三方服务）。虽然我们只选择与那些认同我们对您隐私的尊重的第三方服务合作，但是我们不能对这些第三方服务的内容和隐私政策负责。因此，我们建议您去查看您所访问的第三方服务的隐私政策。</p>

          <p>&nbsp;</p>

          <p><strong>{{ config.name }}</strong><strong>收集哪些信息以及我们如何使用这些信息？</strong></p>

          <p>我们收集的这些信息主要是用于提供服务、处理和完成交易、响应查询、个性化和改善服务、监控和分析使用情况和服务趋势，为您提供相关的信息等。在服务相关的某些方面,我们会要求收集或显示一些您的信息。我们收集用户以下信息:</p>

          <p><strong>用户信息：</strong></p>

          <p>当您创建账户时，我们会收集您的个人信息，例如您的姓名和电子邮件地址。如果您使用已经登录的其他账户来在本网站创建账户，我们会访问和收集您在那个账户中所提供的个人信息（基于您的隐私设置）。我们可能使用您的联系信息来向您发送我们的服务信息，您可以通过账户设置来取消订阅此类消息，但必要时本网站和海内科技保留联系您的权利，比如出于行政和账户管理的目的。</p>

          <p><strong>关于未成年人使用我们的服务</strong><strong>:</strong>我们不会有意收集未成年人的个人信息。如果我们了解到我们收集了18岁以下未成年人的个人信息,我们将采取必要的步骤来从我们的系统通知中删除这些信息。</p>

          <p><strong>网址（</strong><strong>URL</strong><strong>）信息</strong>：</p>

          <p>{{ config.name }}服务的一个特点是创建缩短的网址（URL）的能力。本网站同时收集和存储原始URL和被缩短后的URL，如果您登录到您的账户，我们会将信息关联到您的账户。本网站也收集和存储您缩短原始URL的时间和日期，当您分享链接时，网络信息也将同时被分享。</p>

          <p><strong>指标和分析：</strong></p>

          <p>{{ config.name }}收集由本网站服务所创建的每一个链接的访问（例如点击）信息。这里的信息包括但不仅限于：</p>

          <ol>
            <li>访问链接的设备的IP地址和实际位置；</li>
            <li>相关的网址和设备；</li>
            <li>每次访问的时间和日期；</li>
            <li>链接在第三方服务分享的信息。</li>
          </ol>

          <p>这些指标和信息将被用于本网站及本网站合作伙伴提高服务质量，例如提供增值功能等。</p>

          <p><strong>用户内容：</strong></p>

          <p>本服务的某些功能允许您提供一些内容服务，比如URL的书面描述、评论、图片和视频等。您向服务器提交的所有内容都会被本网站保留,即使在您注销了账户以后这些内容也可能被第三方按照本《隐私政策》中的描述进行分享。</p>

          <p><strong>从第三方服务收集的信息：</strong></p>

          <p>本服务的某些功能允许您通过您的账户和其他公司共享您的内容和链接。如果您选择将本网站和第三方服务相连接，我们会收集与您使用第三方服务相关的信息，例如身份验证令牌，这使我们能够连接您的第三方服务帐户或者您朋友们的联系方式（如果您选择了联系您的朋友、粉丝或者您账户中的联系方式），这样当您使用服务时您可以联系这些人。我们也会收集有关您如何使用本服务来与连接的第三方服务交互的信息。需要注意的是，基于您账户的隐私设置第三方服务可能具有限制提供给我们信息的能力。</p>

          <p><strong>自动收集的信息：</strong></p>

          <p>{{ config.name }}会自动接收并记录您的浏览器和服务器日志上的信息，其中包括您的 IP 地址、浏览器类型、一般位置和 cookie信息。这些信息将用于帮助我们理解用户如何传播网站,用来打击垃圾邮件/恶意软件,促进有关您与服务交互数据的集合以及其他相似的用途。</p>

          <p>当您访问服务时{{ config.name }}也会自动接收和记录您的移动设备传输信息，例如设备标识符、设备设置和操作系统。这些信息是用来促进您的访问以及提高对本服务的使用频率,访问和使用其他第三方服务以及其他相似的用途。</p>

          <p>一般情况下，服务器会自动收集使用信息，例如网站访客的数量和频率信息。本网站可以集合形式使用这些数据，也就是说，作为统计的措施，但不是以识别您个人的方式。这种类型的汇总数据使我们和我们授权的第三方了解到个人使用部分服务的频次，使得我们可以分析和改进服务。</p>

          <p>当您访问网站时，我们会收集您的一般位置信息（例如您的城市和国家，这来自于您的IP地址）。当您通过移动设备访问和使用服务时，我们可以使用各种技术来确定您的位置(如果您启用了这个功能)。</p>

          <p>当您打开我们发送的邮件时我们会收到确认，我们以此来提升客户服务。</p>

          <p><strong>Cookie&nbsp;</strong><strong>政策</strong></p>

          <p>Cookie是当您访问一个网站时，通过Web浏览器提供给您的计算机的文本片段。您的浏览器以您访问的每个网站相关联的方式存储cookie。我们使用cookie使我们的服务器识别出您的Web浏览器，并告诉我们您访问我们网站和通过网络使用服务的方式和时间。</p>

          <p>{{ config.name }}的cookie也使得本网站可以在您点击一个短链接时进行跟踪。短链接的每一次点击都使用分配给您的，存储在与本网站相关的web浏览器上的一个或多个cookie上的唯一标识符进行跟踪。</p>

          <p>本网站的cookie不包含任何关于您的个人信息,我们不把通过cookie收集的一般信息和您的个人信息相结合来确定您的身份。然而,我们使用cookie来识别出您的web浏览器访问了本网站的链接,同时如果您拥有本网站的账户，则可以将这些信息与您的账户相关联。</p>

          <p>大多数浏览器有一个关掉cookie功能的选项,这将阻止您的浏览器接受新cookie,以及允许您决定以多种方式接受新cookie(取决于您的浏览器软件的复杂性)。如果您禁用cookie,您将不能使用大多数的特色服务。本网站的cookie被设置成一个月后自动失效。</p>

          <p>您可能也会要求本网站不要在您的web浏览器上放置cookie。当cookie以这种方式被禁用，点击本网站的链接将不会绑回或与您的web浏览器相关联。</p>

          <p>本《隐私政策》只涵盖本网站cooike的使用，不包含任何第三方服务对与cookie的使用。本网站不控制第三方在您的电脑上放置cookie的时间和方式，例如当您从本网站的链接访问一个网址时。</p>

          <p>如果您是海内科技的多个应用程序和服务的用户,我们可以将本网站的cookie与那些应用程序和服务的cookie相结合，以此为您提供单点登录服务。这是为了使您更容易地访问多个服务，同时限制了每一次当您注册我们的服务时所需要提供的信息量。此外,它会为您提供相关的内容或信息,并协助本网站的行政和账户管理功能。</p>

          <p><strong>什么是信息共享？</strong></p>

          <p>本服务旨在帮助您和其他人分享信息。因此，大部分通过服务生成的信息是被公开或与第三方共享的。</p>

          <p><strong>链接和使用信息：</strong></p>

          <p>您在本服务上的大部分活动默认是公开的，这包括但不仅限于：</p>

          <p>1. 您所缩短的原始URL，它们相应的短链接，缩短URL的时间和日期，您的链接的指标和分析信息，如上所述；</p>

          <p>2. 关于您点击的链接的非个人信息集合和与之相关的指标和分析信息，如上所述；</p>

          <p>3．您创建的每一个自定义链接和相关内容（如果您选择让这些内容公开）。</p>

          <p>注册用户会有一些与他们的帐户相关的信息。未注册的用户将不会有此关联，但有关其使用服务的信息（他们点击了什么链接，点击的时间，点击的地址等）将会被存储在本网站上的cookie进行匿名跟踪。</p>

          <p>对于注册用户，一些信息是通过一个特定的URL可以公开访问的，当您登录时您可以通过您的账户设置将其设为非公开。即使您通过停止对这个特定的URL的访问来使信息保密，但是通过其他途径比如本网站的API服务仍然可以进行访问。</p>

          <p>本网站的链接和普通使用服务可能会以匿名或者集合形式（即非个人身份）与我们的客户和商业伙伴分享。比如通过服务与URL的所有者或者其他海内科技的应用程序和服务实现共享。这些信息使我们能够更好地了解到用户如何使用我们的产品和服务以及其他的产品和服务，了解用户的兴趣以及我们的服务如何与他们的服务进行互操作，产品开发，市场营销优化和研究，并提高通过服务所展示的在线广告和营销的相关性（或使其符合您的利益）。</p>

          <p>请谨记如果您选择提供关于您使用特定功能的服务信息，这些信息将受该特定功能的隐私设置所管理，从而可以被其他人公开访问。</p>

          <p><strong>IP</strong><strong>地址信息：</strong></p>

          <p>我们收集和存储IP地址信息但这些信息是非公开的。我们有时与我们的合作伙伴、服务提供商以及与我们开展业务的其他公司分享IP地址信息，但大多数情况下，当我们和第三方分享IP地址信息时，我们会使用加密打乱的IP地址，从而使其不能有效逆转成实际的IP地址或单独链接到您。</p>

          <p><strong>您选择分享的信息：</strong></p>

          <p>创建一个链接后您可以通过第三方服务分享这个链接。您选择通过第三方服务来分发的信息也将会提供给他们，之后第三方服务的用户也可以访问这些信息。您也可以通过本服务访问其他的第三方服务，如点击统计页面的链接。由于本网站对于第三方服务的隐私政策不控制、不负责，因此建议您阅读您通过我们的服务所访问的第三方服务的《服务协议》和《隐私政策》。</p>

          <p><strong>用户配置文件信息：</strong></p>

          <p>用户配置文件信息包括用户名和邮箱、手机号（您的账户已与这些服务相联），同时您输入的其他信息也会显示给其他用户以促进服务中的用户交互。但用户的电子邮件地址不会通过本网站直接透露给其他用户。</p>

          <p><strong>与本网站服务供应商的信息分享：</strong></p>

          <p>我们将代表本网站和我们的服务供应商与个人或其他实体以雇佣和合同的方式来完成特定的任务。为了给您提供产品和服务，我们需要和我们的服务供应商分享您的信息。除非我们另行通知您，否则我们的服务供应商没有任何超过为我们提供服务的目的而使用我们分享给他们的您的信息的权利。</p>

          <p><strong>根据业务转让披露的信息：</strong></p>

          <p>用户的信息在我们出售公司资产或其他情况下有可能会作为我们企业资产之一而被转让。在这种情况下，本网站的收购方可以继续根据本政策使用您的信息。</p>

          <p><strong>出于保护本网站及其他目的而披露的信息：</strong></p>

          <p>本网站保留在必要时访问、读取、储存和披露任何信息的权利：</p>

          <p>1. 遵守所有适用法律、法规，应法律程序或政府要求；</p>

          <p>2．执行这些服务条款，包括对潜在违规行为展开调查；</p>

          <p>3. 检测，防止或解决欺诈，安全或技术问题;</p>

          <p>4．响应用户支持请求。</p>

          <p>5．保护本网站用户和公众的权利、财产和安全。这包括与其他公司和组织为防止欺诈和垃圾邮件/恶意软件防范交换信息。</p>

          <p><strong>根据您的同意我们分享的信息：</strong></p>

          <p>我们会在下列情况下分享您的信息：当您和其他人分享本网站的链接和内容时；我们告知您,您提供的信息将会以一种特定的方式被分享时；您在第三方服务分享或发布信息时。</p>

          <p><strong>我的信息是怎样被保护的？</strong></p>

          <p>您的账户信息通过您的密码来保护您的隐私和安全。您可以适当的选择和保护您的密码来阻止非法访问，同时您也可以通过在您完成对账户访问后退出登录来限制对于您的计算机和浏览器的访问。</p>

          <p><strong>我有什么选择</strong><strong>,</strong><strong>我可以访问我的什么信息</strong><strong>?</strong></p>

          <p>您不需要一个帐户就可以创建短链接,在没注册的情况下您可以使用许多服务的功能，从而限制了被单独收集的有关您的信息。</p>

          <p>如果您是一个注册用户，您可以通过登录网站来访问和您账户有关的信息。这些信息包括您缩短的URL历史记录，和这些URL指标页面的访问记录。您可以在账户设置页面随时停用您的账户。如果您关闭了您的账户，您将不能再访问和使用本服务。</p>

          <p>请注意，为了确保现有的链接继续为我们的用户服务，您创建和分享的短链接不能被删除或失效（即使您的账户已停用），同时您账户的每一次缩短和分享活动也不能被删除。如果您对此有疑问请联系：{{ config.email }}。</p>

          <p><strong>在本《隐私政策》变更后怎么办</strong><strong>?</strong></p>

          <p>本网站可能适时修订本《隐私政策》的条款，如果我们在收集和使用信息的方式上发生重大的改变，我们会通过发布公告或给您发送电子邮件的方式来通知您，同时我们也将在通知中写明修订后的《隐私政策》生效的时间。在该种情况下，若您继续使用我们的服务，即表示同意受修订后的《隐私政策》的约束。</p>

          <p>在阅读完本政策之后，如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，请与{{ config.email }}联系。</p>
        </div>
        <!-- <div
          v-else-if="alias == 'about'"
          class="container"
        >
          <p>宿迁海内科技有限公司旗下的{{ config.name }}（{{ config.domain }}）是一家提供完善的网址压缩服务。</p>
          <p>{{ config.name }}（{{ config.domain }}）是一家提供完善的网址压缩服务，追踪，统计，分析链接的解决方案的网站，同时是我国最专业的网址（url）压缩服务提供商之一。</p>
          <p>{{ config.name }}（{{ config.domain }}）创新式的引入了许多新功能，使得用户体验变得极为完美</p>
          <p>{{ config.name }}（{{ config.domain }}）允许用户缩短，分享和追踪他们的链接，简短url的长度使得分享变得更加容易！</p>
          <p>{{ config.name }}（{{ config.domain }}）秉着开放的互联网思想，致力于提供完善便捷的服务，不仅在本站的功能基础上提供服务，而且开放数据接口，向广大第三方用户和合作者提供服务，开放数据接口高效，稳定，同时提供强大的技术支持。</p>
          <p><strong>联系电话：{{ config.mobile }}</strong></p>
          <p><strong>公司地址：{{ config.address }}</strong></p>
        </div> -->
        <div
          v-else-if="alias == 'rules'"
          class="container"
        >
          <p>{{ config.name }}一直致力于为用户提供文明健康、规范有序的网络环境，用户不得利用{{ url }}服务制作、复制、发布、传播如下干扰平台正常运营，以及侵犯其他用户或第三方合法权益的内容。</p>

          <p>对于违反本规范的内容，网站将立即进行处理，包括但不限于停止链接内容继续传播、删除内容、封停用户账号等；{{ config.name }}也有权依照本规范及相关协议，拒绝再向违规主体提供服务。</p>

          <p>具体规则及相关处罚如下：</p>

          <p><strong>1.诱导分享、关注类</strong></p>

          <ul>
            <li>要求用户分享后方可进行下一步操作，分享后才能阅读全文等；</li>
            <li>含有明示或暗示用户分享的文案、图片、按钮、弹层、弹窗等，如：分享给好友、分享到朋友圈、转发、群发等；</li>
            <li>通过利益诱惑用户分享、传播内容，包括但不限于：现金、实物奖品、虚拟奖品（红包、优惠券、代金券、积分、话费、流量、信息等）、集赞、拼团砍价、增加抽奖机会、中奖概率等；</li>
            <li>用夸张言语来胁迫、引诱用户分享，包括但不限于：“不转不是中国人”、“请好心人转发一下”、“转发后一生平安”、“转疯了”、“必转”、“转到你的朋友圈朋友都会感激你”等；</li>
            <li>强制或诱导用户关注公众账号或个人微信号的，包括但不限于关注后查看答案、领取红包、关注后方可参与活动等；</li>
          </ul>

          <p>若内容中包含以上情况，立即停止链接内容继续传播；对于情节恶劣的情况，永久封禁账号。</p>

          <p><strong>2.违规代理类</strong></p>

          <ul>
            <li>刷单刷钻、流量倒卖、卡密充值、外挂代练、非法网络电话、非法棋牌娱乐、非淘宝天猫京东官方的优惠券分发网站、淘宝客等，如卡盟系、僵尸粉业务；</li>
          </ul>

          <p>若内容中包含以上情况，封禁账号、链接删除。对于情节恶劣者，永久封禁账号，记录IP地址等一并上报公安网监部门。</p>

          <p><strong>3.欺诈类</strong></p>

          <ul>
            <li>虚假现金红包、虚假话费卡、虚假流量红包、虚假优惠券、虚假优惠活动等，以赚取现金、实物奖品、虚拟奖品等方式欺骗用户参与；</li>
            <li>没有合法资质、承诺很高很好的借贷活动；金融互助、虚拟货币等相关业务；</li>
            <li>仿冒其它网站的排版，域名，可能造成用户混淆的；</li>
          </ul>

          <p>若内容中包含以上情况，封禁账号、链接被删除。对于情节恶劣者，永久封禁账号，记录IP地址等一并上报公安网监部门。</p>

          <p><strong>4.传销类</strong></p>

          <ul>
            <li>以销售或推销货品（或虚拟物品）为名义，通过拉人入会，收取入会费作为主要盈利途径的行为，即为传销，多层级奖励累计制度尤其常见。</li>
          </ul>

          <p>若内容中包含以上情况，封禁账号、链接被删除。对于情节恶劣者，永久封禁账号，记录IP地址等一并上报公安网监部门。</p>

          <p><strong>5.内容低俗类</strong></p>

          <ul>
            <li>涉及性器官、性行为、性暗示的，传播低级趣味、庸俗、有伤风化内容的，或者宣扬暴力、恶意谩骂、侮辱他人内容的，例如：传播走光、偷拍、露点、一夜情、换妻、性虐待、情色动漫、非法性药品广告和性病治疗广告、推介淫秽色情网址等;</li>
          </ul>

          <p>若内容中包含以上情况，封禁账号、链接被删除。对于情节恶劣者，永久封禁账号，记录IP地址等一并上报公安网监部门。</p>

          <p><strong>6.谣言类</strong></p>

          <ul>
            <li>发送不实信息，制造谣言，可能对他人、企业或其他机构造成损害的，例如自来水有毒、香蕉致癌、小龙虾不能吃等</li>
          </ul>

          <p>若内容中包含以上情况，封禁账号、链接被删除。对于情节恶劣者，永久封禁账号，记录IP地址等一并上报公安网监部门。</p>

          <p><strong>7.骚扰广告、垃圾信息类</strong></p>

          <ul>
            <li>传播骚扰、欺诈、垃圾广告等信息的，包括但不限于虚假中奖类信息，违规保健品、药品、食品类信息，假冒伪劣商品信息，虚假服务信息，虚假网络货币等；</li>
          </ul>

          <p>若内容中包含以上情况，封禁账号、链接被删除。对于情节恶劣者，永久封禁账号，记录IP地址等一并上报公安网监部门。</p>

          <p><strong>8.存在违规广告的网站</strong></p>
          <ul>
            <li>网站中展示违规广告的，常见的例如：盗版影视站（通常服务器在国外，且广告经常涉及色情等内容）、VIP视频解析网站等</li>
          </ul>
          <p><strong>9.安全性未知网址（应用）</strong></p>
          <ul>
            <li>未经过国家相关认证，未提交相关经营资质，存在疑似违规内容的网址和app下载地址。如没有文网文资质的互联网经营性文化产品或服务，没有相关金融牌照和资质的金融类交易平台等；</li>
          </ul>

          <p><strong>10.违反国家法律法规的内容，包括但不限于：</strong></p>

          <p>(1) 违反宪法确定的基本原则的；</p>

          <p>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>

          <p>(3) 损害国家荣誉和利益的；</p>

          <p>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</p>

          <p>(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>

          <p>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>

          <p>(7) 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>

          <p>(8) 侮辱或者诽谤他人，侵害他人合法权益的；</p>

          <p>(9) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>

          <p>(10) 以非法民间组织名义活动的；</p>

          <p>(11) 含有法律、行政法规禁止的其他内容的。</p>

          <p><strong>11.其它：</strong></p>

          <p>(1) 由安全联盟检测出的恶意链接；</p>

          <p>(2) 由腾讯电脑管家、金山毒霸、360、阿里钱盾等安全软件检测出的恶意链接；</p>

          <p>(3) 工信部发布的恶意网站黑名单链接；</p>

          <p>(4) 12321网络不良与垃圾信息举报受理中心发布的垃圾、违法信息链接</p>

          <p>(5) 公安、网信、通管局等政府主管部门通知屏蔽、删除的链接</p>

          <p>
            申诉及常见问题可查看：<a
              href="#"
              target="_blank"
            >《FAQ &amp; SUPPORT》</a>
          </p>

          <p>请用户主动遵守上述条款，也欢迎用户对违规内容投诉举报，一经核实，我们将立即按照规范进行处理。感谢与我们共同努力维护环境！</p>
        </div>
        <div
          v-else
          class="container"
        >
          <p>感谢您使用{{ config.name }}网站（以下简称“本网站”）的服务。宿迁海内科技有限公司（以下简称“海内科技”）运营并管理本网站的服务，并允许用户通过网址缩短和分享服务与家人和朋友们轻松分享内容和链接。本《服务协议》中的使用条款只适用于本网站的服务并不适用于海内科技拥有的其他网站和服务。请在通过网站、插件或软件应用程序使用由海内科技提供的服务、功能和内容之前仔细阅读本《服务协议》（本协议内容包括宿迁海内科技有限公司《隐私政策》）。在您使用本服务时，本《服务协议》中的条款具有法律约束力。</p>

          <p><strong>接受条款：</strong></p>

          <p>请仔细阅读以下的使用条款、其他规则、我们通过服务不时和您沟通的指南，以及附加条款，因为这些条款在您和海内科技之间确立了具有法律约束力的合同。不管您是一个网站的访客（游客），通过发布者网站访问本网站的个人（“最终用户”）或使用本服务包括通过使用插件或品牌URL分享和缩短网址的网站的所有者或者经营者，您使用我们的服务（无论是作为一个游客，最终用户还是发布者），即表示您已阅读、理解、同意受其约束，并遵守这些条款。如果您不同意以下任何内容，您应当立即停止访问本网站或使用本网站服务。除非特别声明，术语“您”，是指一个访问者、最终用户，或发布者。</p>

          <p>通过注册或者其他方式，包括但不仅限于访问、浏览和使用本服务缩短网址，即表示您同意海内科技已发布的服务条款和其他操作规则、政策和程序，包含可能在未通知您的情况下不时引入的更新。</p>

          <p>某些服务可能是受海内科技规定的、并不时更新的附加条款和条件约束；您使用这些服务时同样受本《服务协议》引用的这些附加条款和条件约束。</p>

          <p>这些服务条款适用于本服务的所有使用者，包括但不仅限于内容提供者、信息、其他材料和服务的使用者，注册用户或其他。</p>

          <p><strong>变更：</strong></p>

          <p>海内科技保留其自行决定更改和替换本《服务协议》中任何条款的权利以及在任何时间通过公告或者发送通知或邮件的方式改变、暂停或终止服务的权利。海内科技可以在未通知您的情况下对本网站服务的某些功能增加限制或者限制您访问部分或全部服务。您有责任定期查看这些条款的变更，在变更后的《服务协议》发布后您继续使用本服务即表示您接受这些变更。</p>

          <p><strong>适用：</strong></p>

          <p>使用本服务的个人年龄应为至少13 岁。使用本服务即表示您已年满13岁，您完全可以并有能力遵守这些条款、条件、义务、誓词，并且正从服务中受益。您声明并保证您是年满13岁。如果您在13岁以下，您可能在任何情况下都不能使用本服务。 海内科技可以自行决定，拒绝提供服务给任何个人或实体，并随时更改申请资格。如果您超过13周岁但未满18岁（即“未成年人”），您必须在注册本服务的帐户前征得父母的同意，同时您的父母必须代表您接受这些条款。如果您作为一名家长或法定监护人，同意让您的未成年子女使用服务，您须同意对以下内容负全部责任：</p>

          <p>1. 未成年子女的在线行为；</p>

          <p>2. 监测未成年子女对服务的访问和使用；</p>

          <p>3. 上述未成年子女使用本服务的后果。</p>

          <p>此外，如果您是代表公司、实体、组织或任何上述各方的组或部门（统称为“组织”）使用本服务，那么您代表并保证：您是组织授权的权威代表，并同意代表该组织受这些条款的约束。您负责确保这些服务条款符合所有适用于您的法律、法规和规章，当这些服务条款或服务的使用被禁止或出售、提供服务在某种程度上与适用的法律、法规相冲突时，您访问本服务的权利将被取消。此外，该服务只提供给您使用，而不提供给任何第三方使用或受益。</p>

          <p><strong>注册:</strong></p>

          <p>虽然服务的某些功能提供给未注册的用户，但是为了使用服务更广泛的功能您必须注册（创建一个账户）。为了注册，您必须提供准确的电子邮件地址或手机号，并选择一个用户名。您负责更新您提供给海内科技的、与您的帐户关联的电子邮件地址的准确性。对于用户名，您不能：1.以冒充他人的意图来选择使用他人的用户名为您的用户名；2.选择或使用未经适当授权的不属于您的个人或实体的名称为您的用户名；3.选择和使用其他粗俗或淫秽的名称为您的用户名。</p>

          <p><strong>账户安全:</strong></p>

          <p>您对您账户的活动和安全负全部责任。未经允许您不能使用其他账户。如果您的账户出现安全或非法使用的问题请立即联系我们。您不应该公布、分发或发布您账户的登录信息。</p>

          <p><strong>所有权声明：</strong></p>

          <p>本服务由宿迁海内科技有限公司拥有并运营。本网站的可视化界面、多媒体内容（包括音乐作品和录音）、特征信息、图形、设计、编译、计算机代码、软件产品以及由海内科技提供的服务中的其他材料是海内科技或者其授权的宝贵专利和知识产权。这些材料不包括用户内容（如在知识产权政策中定义，包括但不限于链接）海内科技的材料受适用法律、法规和有关知识产权或专有财产法规的保护，包括但不限于：专利、版权、商标、商业秘密的法律。您同意不复制、分发、展示、修改、创造衍生产品、发布、销售、许可，或编辑任何海内科技材料。任何违反这些条款或适用的许可而试图下载、打印、发布或保存海内科技材料、分发海内科技材料的副本，或其他违反知识产权或其他专有权利利用海内科技材料的行为都是这些条款所严格禁止的。</p>

          <p>{{ config.name }}服务</p>

          <p><strong>本服务允许：</strong></p>

          <p>1. 最终用户和发布者缩短、跟踪和分享使用本网站域名的网址；</p>

          <p>2. 最终用户通过我们的链接缩短服务与其他人分享位于发布者网站上的内容。对于发布者，该服务还包括访问分析软件、发布仪表板、广告标签和由海内科技提供的其他工具和应用程序。 海内科技目前对部分功能不收取费用。然而，我们为了更有效的定制广告给您可能会收集信息，这在本网站的《隐私政策》中有更详尽的阐述。</p>

          <p>{{ config.name }}收费和充值服务:</p>

          <p>1.用户应按照实际需求进行充值和消费，已消费金额不予退款</p>

          <p>2.用户因违规导致帐号被我站封禁，付费服务将被停止且不予退款</p>

          <p>3.账户余额资金仅在我站无法继续履行增值服务时退款，因用户个人原因不予退款</p>

          <p><strong>{{ config.name }}链接</strong></p>

          <p>本服务允许您使用本网站的域名作为链接去缩短和跟踪网址（URL）。只要您遵照本《服务协议》，您就可以以非商业目的的个人身份使用本服务。如果您想将本服务用作商业目的请联系我们。</p>

          <p>统计：</p>

          <p>1．定义：</p>

          <p>该服务的某些功能允许海内科技收集和跟踪与使用服务缩短的网址有关的、由服务制造的缩短网址束、其他与服务使用相关的指标并生成分析统计数据。本网站的统计包括但不限于：</p>

          <p>
            某一账户缩短URL的历史记录；<br>
            点击短网址的历史记录；<br>
            通过第三方服务或者海内科技的其他应用程序分享短网址的历史记录；<br>
            点击短网址相关网址的历史记录；<br>
            访问短网址的IP地址历史记录；<br>
            任何或所有上述的视觉呈现（不包括IP地址）。<br>
            2．所有权
          </p>

          <p>您承认和同意所有的{{ config.name }}的统计都是由海内科技收集、生成和拥有，海内科技有权使用、许可、出售或以任何目的以其他方式处置这些统计。</p>

          <p>3．统计数据</p>

          <p>在您继续遵守这些服务条款的前提下，本网站提供给您服务。作为服务的一部分，海内科技收集和生成关于您使用本服务的各种数据。如果您在网站上放置了一个插件，在您使用服务分享链接或者使用服务时我们会在最终用户的web浏览器上放置cookie来收集信息，包括但不仅限于浏览器类型、最终用户在访问放置插件的网站时或者放置之前和之后所访问的网站，最终用户访问这些网站的时间和日期，同在《隐私政策》中的详尽阐述。您只能以个人身份或者与您合作的发布者网站的操作和分析的内部业务使用我们的分析工具和从这些工具中获得的数据。您不能使用本服务（或允许第三方使用本服务）去跟踪和收集其他用户的个人身份信息（PII），同时您也不能将您从网址中获取的数据和任何数据相联系（或允许第三方去联系）。您也不能在未经我们书面同意的情况下分享、披露、许可或提供分析数据给其他第三方广告网络。</p>

          <p>“品牌URL”服务的发布者条款</p>

          <p>本服务也允许“品牌URL”的使用，运用与发布者品牌有关的域名，同时允许发布者分享和跟踪的带有广告商标志的“品牌URL”。这种品牌URL可以是发布者通过网站界面的添加短网址界面的“高级功能”服务得到的。如果您是发布者，希望使用本服务中的“品牌URL”服务功能，需要符合以下条款。</p>

          <p>1. 品牌URL许可。遵守和接受这些条款和条件，发布者在此授予海内科技非排他性的、免版税的、不可转许，仅根据提供品牌URL服务的需要使用品牌URL，发布者保留品牌URL的全部所有权。发布者代表和授权：它保有对品牌URL充分的权利（包括但不限于以下的知识产权及所载的任何第三方内容），包括但不仅限于修改和创建品牌URL上的任何材料的衍生作品。发布者将赔偿、辩护，并保证海内科技免受所有损害、责任，以及因发布者违反上述条款所导致的费用和开支（包括但不限于律师费）。</p>

          <p>
            API许可。根据这些条款，海内科技特此授予发布者非排他性的，不可转让，不可转许可，以使用品牌URL为唯一目而使用海内科技的0×3.me应用程序编程接口。 海内科技的品牌URL服务提供的条件是发布者同意遵守这些条款。<br>
            API许可限制。发布者不得将本网站的API用于以上阐述项以外的任何其他目的。未经允许，发布者不得：（1）使用本网站API为开发将由第三方所拥有或将与海内科技的产品或服务进行竞争的产品的目的； （2）再许可、出租、出借，允许第三方访问或使用的本网站的API； （3）除非条款明确准许，否则不得复制、分发、出售，使用或允许访问本网站 API； （4）修改、改编、翻译，反向工程或反编译（除非法律明确授权的范围内），或从本网站 API制作衍生作品；（5）删除、隐藏或改变海内科技的版权声明、商标或包含在本网站API中的其他所有权声明；（6）使用本网站 API禁用、覆盖或干涉服务。<br>
            ．品牌URL的所有权。在公司与海内科技之间：（1）公司保留品牌URL的内容、利益和相关内容的所有权；（2）除依照本条款授予公司有限的权利和许可，海内科技保留服务和API的所有权和利益。海内科技拥有通过我们网络服务的使用而收集的数据和信息，而您拥有从您的网站收集的所有数据和信息。<br>
            插件的发布者条款
          </p>

          <p>如果您是一个发布者，您希望使用本服务允许使用者分享您的网站上的内容，您需要适用以下条款：</p>

          <p>1. 网站审批和许可。根据这些条款，您可以实现发布商网站上的服务，而不需要海内科技的事先批准，同时海内科技特此授予发布商非排他性的、不可转让、不可转许可的，以参与服务为唯一目的使用发布商网站上的插件的权利。海内科技保留拒绝或撤销任何网站参与服务和自行决定随时终止本许可的权利。</p>

          <p>2. 实施。在这里提供的用来实现在发布商网站上的插件的工具和说明，发布者应遵循执行插件件的指令，并在所有发布者网站上执行该插件。</p>

          <p>3．许可限制。发布者不能将插件用于以上阐述项以外任何的其他目的。未经允许，发布者不得：（1）使用插件为开发将由第三方所拥有或将与海内科技的产品或服务进行竞争的产品的目的； （2）再许可、出租、出借，允许第三方访问或使用的本网站的插件； （3）除非条款明确准许，否则不得复制、分发、出售，使用或允许访问本网站插件； （4）修改、改编、翻译，制作衍生作品； （5）删除、隐藏或改变海内科技的版权声明、商标或包含在本网站插件中的其他所有权声明；（6）在未经海内科技书面许可的情况下复制、发布、再生、出售，允许访问海内科技的广告标签将其用于横幅广告或其他目的。</p>

          <p>4．发布者网站。发布者代表和授权：（1）发布者保留网站适当的权利（包括但不限于知识产权及所载的任何第三方内容）以经营服务，包括但不限于修改和创建的发布网站和任何材料的衍生作品的权利；（2）发布者网站不描绘毒品，并且不包含色情或成人内容；或以其他方式宣扬暴力、非法活动或任何侵犯他人权利的内容。发布者将赔偿、辩护并保持海内科技免受所有损害、责任，以及海内科技因发布者违反上述所述条款的行为而导致的费用和开支（包括但不限于律师费）。</p>

          <p>5. 内容分享。本服务允许使用者轻松的从发布者网站上分享内容给其他人。同样的，我们也不宣布在发布者网站上的内容的所有权。通过在您的网站上放置小插件，您授予我们一个世界性的、非排他性的、已支付的、可分配的、免税的权利和许可去分发、表演、展示和再现您的内容，以通过服务为您提供内容为唯一目的。</p>

          <p>6．发布者网站的所有权。在发布者和海内科技之间：（1）发布者保留发布者网站及其内容的所有权利和利益；（2）除依照本条款授予发布者有限的权利和许可，海内科技保留服务和插件的所有权和利益。海内科技拥有通过我们网络服务的使用而收集的数据和信息，而您拥有从您的网站收集的所有数据和信息。</p>

          <p>内容</p>

          <p>1. 定义：</p>

          <p>对于这些服务条款的目的，术语“内容”包括但不限于，网址、短网址、定制个性化网址、策划的网址、视频、音频剪辑，书面文章和评论、信息、数据、生成的文本、照片、软件、脚本、图形和交互功能，提供或以其他方式通过服务进行访问。</p>

          <p>
            用户内容：<br>
            所有用户添加、创建、上传、提交，发布到本服务上的内容，无论是公开发布的还是私下上传的（统称为“用户内容”），全部由发起它的用户自行负责。您承认由您承担使用本服务访问的所有内容的风险、损害或损失或由此引起的任何结果的全部责任。当您删除用户内容，它将从服务中删除。但是，您需要理解：（1）一些用户内容（例如以前短网址及相关分析）将依照隐私政策所述保留一段时间。（2）受我们的隐私政策约束，任何删除的用户内容可以在一个备份副本中保留一段合理的时间（但删除后不能与他人分享）。
          </p>

          <p>
            海内科技内容<br>
            本服务包含由海内科技或其合作伙伴提供的内容受版权、商标、服务商标，专利、商业秘密或其他专有权利和法律的保护。这些内容包括但不限于短链接分析。您应遵守和维护，经由本服务访问的任何内容的所有版权声明、信息和限制。
          </p>

          <p>4．使用许可</p>

          <p>遵照这些服务条款，海内科技授予本服务的每个用户一个全球性、非排他性、不可分许可的、不可转让的使用内容的许可，仅供个人，非商业用途来使用部分服务。以非个人和商业目的使用、复制、修改，分发或存储任何内容是明确禁止的。如果您想将次服务用于商业用途，则必须与我们联系。</p>

          <p>&nbsp;</p>

          <p>5.许可授权</p>

          <p>（1）授权给海内科技</p>

          <p>通过本服务提交用户内容，您应给予海内科技一个全球性、非排他性的、免版税的、完全付清，再授权及转让的使用许可去使用、编辑、修改、复制、发行、制作衍生作品、展示、执行等充分开发与本服务和海内科技商业活动有关的用户内容，包括但不限于以任何媒体形式通过任何媒体通道（包括但不限于第三方网站和种子）宣传和重新分发全部或部分服务（及其衍生作品）。</p>

          <p>（2）授权给用户</p>

          <p>您在此应给予服务的每个用户非排他性的许可去通过服务来访问您的用户内容，以及使用、编辑、修改、复制、发行，制作展示衍生作品和执行此类用户内容。</p>

          <p>（3）没有侵权</p>

          <p>您在此应给予服务的每个用户非排他性的许可使其通过服务来访问您的用户内容，以及使用、编辑、修改、复制、发行，制作展示衍生作品和执行此类用户内容。</p>

          <p>6．可用性内容</p>

          <p>海内科技不保证服务可以提供任何内容。此外，海内科技没有义务监督服务。但是海内科技保留以下权利：（1）在任何时候，在不通知您的前提下，以任何理由（包括但不限于，在收到来自第三方或当局有关这些内容的索赔或指控时，或者海内科技认为您已经违反了这些服务条款）删除、编辑或修改自行决定的任何内容；（2）删除或阻止本服务的任何内容。</p>

          <p>行为准则</p>

          <p>1．在使用本网站服务时请保证不将服务用于本《服务协议》禁止的目的，同时您将对服务相关的所有活动负责。</p>

          <p>2．您不得，也不得允许任何第三方采取任何行动或上传、下载、发布、提交、分发或促进服务上内容的分发（包括用户内容）：</p>

          <p>（1）侵犯专利权、商标权、商业秘密、版权、公开权或其他人或实体的权利，或违反任何法律或合同义务；</p>

          <p>（2）对海内科技的材料或用户内容或任何其他部分，在法律允许的范围之外进行从事、引起、允许或授权修改、创作衍生作品、翻译、逆向工程、编译，反编译、拆卸、黑客服务或其他活动；</p>

          <p>（3）在这些条款允许的范围之外再现、复制、拷贝、销售、交易、转售、分发或开发、服务、使用服务、访问服务或通过服务获取内容（包括但不限于海内科技的材料和用户内容）；</p>

          <p>（4）以比较和竞争分析的目的访问和使用本服务；</p>

          <p>（5）对服务安全相关的功能、阻止或限制使用或复制服务部分内容的功能、限制服务或内容的功能进行删除、规避、禁用、损坏或干扰；</p>

          <p>（6）使用任何机器人、蜘蛛、爬虫或其他任何形式的自动化手段来访问服务、仅在这些条款的允许范围内的服务的功能、服务上内容或功能的深层链接或未经我们书面许可以任何目的附上我们的商标或部分服务，或者其他我们用来限制服务访问的手段；</p>

          <p>（7）含有威胁、辱骂、骚扰、诽谤、中伤、欺诈、侵犯他人隐私等非法内容；</p>

          <p>（8）未经授权的广告、垃圾或群发邮件（垃圾邮件）；</p>

          <p>（9）发布、分发或传播任何不恰当的、粗俗、诽谤、侵权、淫秽、非法的、攻击性的，不道德或其他不良的材料或信息；</p>

          <p>（10）创建虚假身份或以误导他人的目的冒充寄件人身份或信息来源，包括但不限于提供给服务反馈系统误导性信息。</p>

          <p>（11）传播或上传含有病毒、木马程序、蠕虫、轰炸程序或其他有害、有毒的软件程序；</p>

          <p>（12）重定向，分发或从其他用户的帐户秘密收集私人信息（“网络钓鱼”）；</p>

          <p>（13）包含用于破坏、损害、限制或干涉任何软件、硬件或通讯设备应有的功能，非法访问系统数据、密码或其他海内科技或第三方信息的软件病毒或其他计算机代码、文件或程序；</p>

          <p>（14）冒充任何个人或实体，包括任何员工或代表海内科技；</p>

          <p>（15）包括他人的身份证明文件或敏感的财务信息；</p>

          <p>（16）干扰或中断服务、网络或与服务有关的服务器，或者违反违反网络或服务器的规则、政策或程序；</p>

          <p>（17）上传、发布或传播未经授权的或违法的广告、促销材料、垃圾邮件、连锁信、促销方案或其他形式的邀约（商业或其他）；</p>

          <p>
            以任何可能导致违反法律法规的方式使用本服务；<br>
            由海内科技自行决定的其他行为；<br>
            3．您不得：（1）采取任何可能施加给（由海内科技单方面确定）海内科技（或其第三方供应商）的基础设施不合理或不相称的大负荷的行动； （2）干扰或试图干扰服务或服务管理的其他活动的正常运行； （3）绕过海内科技用以防止或限制访问服务（或其他账户、电计算机系统或与服务相关的网络）的任何措施； （4）在服务上运行任何形式的自动应答或“垃圾邮件”； （5）用手动或自动软件、设备、或其他进程抓取或“蜘蛛”服务的任何页； （6）从服务中窃取内容；（7）其他违反海内科技的方针和政策的任何行动。
          </p>

          <p>4．您不得（直接或间接）：（1）在适用法律禁止的范围内破译、反编译、反汇编，逆向工程或以其他方式试图获得任何源代码或相关服务的任何方面的特征的想法或算法； （2）修改、翻译，或创建服务的任何部分的衍生作品；（3）复制、出租、租赁，分发或以其他方式转让任何您在本协议项下的权利。您应遵守所有适用的国家和国际法律、法规的规定。</p>

          <p>5.海内科技保留在必要时访问、读取、保存和披露任何信息的权利：（1）遵守所有适用法律，法规，法律程序或应政府要求； （2）执行本《服务协议》，其中包括对潜在的违规行为展开调查； （3）检测，防止或解决欺诈、安全或技术问题； （4）响应用户的支持请求；（5）保护海内科技及其用户和公众的权利、财产和安全。这包括与其他公司和组织为防止欺诈和阻止垃圾邮件而交换信息。</p>

          <p>&nbsp;</p>

          <p>第三方内容，链接和服务</p>

          <p>分享或连接到本服务的内容可能包含引用或链接的不受海内科技及其供应商和许可方控制的第三方的材料和服务（包括但不限于第三方网站）。</p>

          <p>海内科技为了方便您提供了这样的信息和链接，不应该认为我们推荐此类网站及其上的内容、产品或信息。{{ config.name }}的服务可允许您链接到互联网上的其他网站、服务或资源，其他服务或资源也可能包含本服务的链接。当您访问互联网上的第三方资源时，您需要自己承担风险。这些其他资源不在海内科技的控制之下，您承认海内科技不对这些网站和资源的内容、功能、准确性、合法性、适当性，或任何其他方面负责。此类链接中包含的内容并不意味着海内科技或其运营商认可和推荐。您的通信、商业往来或通过服务参与的促销活动完全是您与广告商之间的活动。您同意海内科技对于此类交易或广告服务的任何结果、损失或损害不负任何责任。另外当您离开本服务时（比如通过点击一个链接）这些条款也不再生效，您应该查看您访问的第三方网站、软件或服务的使用条款和政策包括相关的《隐私政策》。</p>

          <p>发布者披露内容</p>

          <p>发布者代表和授权发布者网站上的每一个网页和品牌网址都包含《隐私政策》的链接，包含的语言类似于如下：“我们允许第三方公司在您访问我们的网站时收集某些匿名信息。在您访问本网站和其他网站时这些公司可能会使用非个人身份信息，以提供给您您可能更感兴趣的有关商品和服务的广告。这些公司通常使用cookie或第三方网络信标收集这些信息。发布者将保证海内科技免受因发布者违反《服务协议》的行为所导致的损害、责任、成本和费用（包括但不限于律师费）。</p>

          <p>用户分歧</p>

          <p>您使用本服务可能将您与其他用户及他们的用户内容相联系，比如通过与服务的其他用户分享链接。您对于您与其他用户的参与承担全部责任。如果您与一个或多个用户发生纠纷，您所承担的与此纠纷有关的所有类型和性质的（包括但不限于实际的，特殊的，间接的和惩罚性的）已知的未知的索赔、要求、损害和赔偿都与海内科技（以及海内科技的管理人员、董事、代理人、子公司、合资企业和员工）无关。</p>

          <p>服务的终止</p>

          <p>1．由海内科技终止服务。 海内科技，自行决定以任何理由或没有理由在任何时间终止用户对服务（或其任何部分）的访问，终止服务和所有相关的服务，包括支持（或其任何部分），或终止本协议授予您的任何许可而无须另行通知。您同意海内科技不对您或任何第三方的服务终止负责。 海内科技不允许在服务中进行非法、侵权、欺诈或其他非法活动，并有权在实际或涉嫌欺诈，或违反本条款及其他法律、法规时暂停或终止接入服务。任何涉嫌欺诈，滥用或非法活动均由相关执法部门负责。 海内科技没有任何责任提供或允许您访问您的用户内容或因使用服务而存储的其他信息。</p>

          <p>2．由您（用户）终止服务。如果您不满意的服务，那么请给我们发送电子邮件{{ config.email }}让我们知道。如果您对于服务、任何条款、海内科技经营服务的任何政策或做法、任何内容或通过服务所传送或使用的信息不满意而想要终止服务，您可以采取以下措施：（1）关闭您的帐户并停止对服务任何部分的使用；（2）通过 {{ config.email }}提供终止通知给海内科技。</p>

          <p>3．在终止时的权利和义务。在这些条款终止之后，您使用该服务的权利将立即终止。发布者应立即采取如下措施：（1）删除发布者网站上的插件（2）停止使用{{ config.name }} API和服务。此外，按其性质或明示条款要保留的规定将终止或期满。</p>

          <p>免责声明</p>

          <p>1. 海内科技与您没有任何特殊关系或信托责任。您承认海内科技在没有支配和采取相关行动的责任：</p>

          <p>
            哪些用户能访问服务；<br>
            通过服务可以访问那些内容；<br>
            这些内容将会对您产生什么影响；<br>
            您怎样解释和使用这些内容；<br>
            内容被披露您可以采取那些行动。<br>
            2．您，而不是海内科技对您从服务中获得的或没获得的内容负责。服务可能包含，或者指引您去一些可能不适当的网站。海内科技不代表任何包含的内容或者通过服务访问的内容，海内科技也不对这些内容的准确性、版权合规性、合法性或包含或材料的系统性负责。
          </p>

          <p>3. 明确否认。海内科技及其董事、员工、代理商、供应商、合作伙伴和内容提供商不保证：（1）本服务将在任何特定时间或地点安全或可用； （2）每一个缺陷或错误都将得到纠正； （3）通过服务得到的内容或软件都不含有病毒或有害成分；（4）使用服务可以满足您的需要。您对服务的使用是完全由您自己承担风险。</p>

          <p>4. 海内科技不保证任何本服务上的或连接到服务的其他网站传送的通讯或信息的机密性和隐私性。 海内科技也不保证电子邮件地址、登记和识别信息，磁盘空间、商业秘密或机密信息，存储在海内科技的设备的任何其他内容、由服务访问网络传输的内容或者与服务使用有关的其他内容的隐私性。</p>

          <p>赔偿</p>

          <p>您应维护、赔偿和保证海内科技、其附属公司及其各自的员工、承包商、董事免于由于您访问、使用或滥用本服务、内容、用户内容、您或者第三方使用您的账户违反服务协议、侵犯个人或实体的知识产权而引起的负债、索赔和费用，包括合理的律师费。海内科技保留防御和控制的权利，在这种情况下，您将协助和维护海内科技的权利。</p>

          <p>责任限制</p>

          <p>在任何情况下海内科技、其附属公司及其各自的员工、承包商、董事，代理商、合作伙伴、供应商、代表或内容提供商都会承担合同责任、绝不侵权或违反关于服务的法律法规：（1）利润损失、数据丢失、采购替代商品或服务，或任何种类的特殊的，直接的，间接的，附带的，惩罚性的或后果性损害，替代商品或服务（不论如何产生）的成本；（2）任何错误，病毒，木马等（不论其来自何处的来源）。</p>

          <p>免责声明：服务（包括但不限于任何内容，或者海内科技提供的其他服务或信息）、用户内容和任何第三方媒体、内容、软件、服务带有或提供的的服务或应用程序都在“原样”、“可用”、“包含所有缺陷”的基础上提供的，不以任何形式保证，包括但不限于正确性、准确性、可靠性或其他方面。</p>

          <p>为了在最大程度上遵守适用法律，海内科技及其附属公司、合作伙伴、许可人和供应商在此否认所有明示、暗示和任何形式的法定保证，包括但不限于适合某以特定用途的适销性的隐含保证、非侵权。 海内科技及其附属公司、合作伙伴、许可人，和供应商不保证该服务或其任何部分，包括但不限于任何用户内容，不会中断或没有错误，不保证没有病毒或其他有害成分，不保证任何前述事项将被校正。您理解并同意，您在您自己的风险判断下使用、访问、下载，或通过服务获取信息、材料、软件、内容或数据，您将对您在下载和使用这些材料或数据的过程中的财产（包括您的计算机系统、手持设备，或其他与服务相连的外围设备）损失和数据丢失承担全部责任。</p>

          <p>限制</p>

          <p>适用的法律可能不允许限制或排除偶然或间接损失的责任，因此上述限制或排除可能对您不适用。在这种情况下，您同意免责声明和责任限制所表达的您和海内科技之间的一个合理和公平的风险分配，这是您和海内科技之间合作的基础，海内科技的责任将仅限于在法律允许的最大范围内。您了解并同意没有这些限制海内科技将不能在经纪可行的基础上提供服务给您。</p>

          <p>适用法律和司法管辖权</p>

          <p>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律。</p>

          <p>凡因本协议引起的或与本协议有关的任何争议，均应提交中国国际经济贸易仲裁委员会香港仲裁中心，按照仲裁申请时中国国际经济贸易仲裁委员会现行有效的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。</p>

          <p>注意事项</p>

          <p>海内科技可以为您提供通过电子邮件、普通邮件或发布在服务的通知。如果通知是通过电子邮件提供的，通知将在电子邮件发送24小时后被视为送达，除非发送方被通知电子邮件地址无效。如果通知是通过服务发布的，那么在您查看过后通知将被视为送达。另外，我们可以为您将法律通知邮寄到邮政地址，如果通过服务您提供了邮政地址。在这种情况下，通知将被视为邮寄之日起三天后送达。</p>

          <p>建议</p>

          <p>我们欢迎您来信向我们提出有关服务的意见建议。如果您给我们一个经营理念或建议（例如，如何提高或扩大我们的产品和服务），您同意，我们将可以以任何方式完全自由地实施、使用或修改您的想法或建议（或其任何部分），没有任何付款或其他义务给您。</p>

          <p>协议的完整性和可分割性</p>

          <p>本《服务协议》是相对于服务，建立在您和海内科技之间，并取代所有以前或同期的通讯和建议（无论是口头，书面或电子）的全部协议。如果发现这些服务条款中的某一条款无法执行或无效，该条款将被限制或消除到必要的最低限度，从而使这些服务条款仍然具有十足效力及作用，并强制执行。除非经双方签署的书面文件，或服务条款发生如上所述的修订，否则这些条款不得修改或放弃。海内科技未能在任何一个或多个实例中执行这里的条款，不应被解释为对该条款的先前或未来表现的放弃。您同意您和海内科技之间不因这些条款和使用服务而产生任何合资、合伙、雇佣或代理关系。您进一步承认您和海内科技之间不因遵照服务协议提交用户内容而产生任何机密、信托、暗示合同或其他关系。您不得转让和分配本《服务协议》所授予的任何权利和许可。但是海内科技在本协定适用范围内依法不受限制地分配、重组，重新纳入、合并或出售全部或实质上全部的操作资产。任何违反这些条款的转让企图均属无效。</p>

          <p>其他</p>

          <p>1．不可抗力</p>

          <p>海内科技对于由于超出海内科技合理控制范围而引起的履行义务的失败不负责，包括但不限于机械、电子或通信故障或性能下降。</p>

          <p>2．分配</p>

          <p>这些服务协议是针对您个人的，没有海内科技事先的书面同意您是不能转让或再授权的。海内科技可以在未经同意的情况下转让、转移或授予任何权利和义务。</p>

          <p>3．机构</p>

          <p>任何因这些服务条款而建立的代理、合伙、合资或雇佣关系，双方都没有在任何方面以任何形式去约束另一方的权利。</p>

          <p>4．通知</p>

          <p>除非本《服务协议》中另有规定，本服务条款下的所有通知都将以书面形式发送。如果亲自送达或发送挂号信，则在收到回执时视为已送达；如果通过传真或电子邮件传送，在收到电子确认时视为已送达；如果以次日到达快递发出，则发出次日视为已送达。</p>

          <p>5．不弃权</p>

          <p>海内科技执行这些服务条款的某一部分失败不构成对其以后强制执行本服务条款的任何其他部分的权利的放弃。遵守任何特定实例的放弃，并不意味着我们会在将来也会如此。为了对于遵守这些服务条款进行约束，海内科技必须向您提供由其授权代表之一提供的书面弃权通知。</p>

          <p>6．标题</p>

          <p>在这些服务条款的章节和段落，标题仅为方便，并不影响其解释。</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="container">
        <!-- <div class="webside">
          <div><a href="">关于我们</a></div>
          <div><a href="">FAQ & SUPPORT</a></div>
        </div> -->
        <div class="info">
          <div class="contant">
            <div class="copyright">
              © 2021  {{ config.name }} 版权所有
            </div>
            <!-- <div class="link">
              <a href="/page/about">关于{{ config.abbreviation }}</a>
            </div> -->
            <div class="link">
              <a href="/page/eula">用户协议</a>
            </div>
            <div class="link">
              <a href="/page/privacy">隐私政策</a>
            </div>
            <div class="link">
              <a href="/page/rules">链接内容管理</a>
            </div>
          </div>
          <div class="ba">
            <a href="https://beian.miit.gov.cn/#/Integrated/index">{{ config.icp }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useRoute } from 'vue-router'
import { defineComponent, reactive, toRefs } from 'vue'
import Header from './components/Header.vue'

declare let globalConfig:any

export default defineComponent({
  components: {
    Header
  },
  setup () {
    const route = useRoute()
    const state = reactive({
      config: globalConfig,
      url: window.location.host,
      alias: route.params.alias
    })

    const stateAsRefs = toRefs(state)

    switch (state.alias) {
      // case 'about':
      //   document.title = '关于' + state.config.abbreviation + '—缩吧短网址'
      //   break
      case 'eula':
        document.title = '用户协议—缩吧短网址'
        break
      case 'privacy':
        document.title = '隐私政策—缩吧短网址'
        break
      case 'rules':
        document.title = '链接内容管理—缩吧短网址'
        break
    }

    return {
      ...stateAsRefs
    }
  }
})
</script>

<style lang="scss" scoped>
  #pageMode{
    width: 100%;
    min-height: 100vh;
    img{display: block;}
    >.head,>.body,>.footer{
      width: 100%;
    }
    >.head{
      position: absolute;
      z-index: 100;
      >.container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >.logo{
          width: 100px;
          >img{
            width: 100%;
          }
        }
        >.control{
          display: flex;
          align-items: center;
          >.tag{
            font-size: 12px;
            color: #fff;
            >div{
              cursor: pointer;
              >p{
                opacity: .5;
                transition: opacity .35s;
              }
            }
            >div:hover{
              >p{
                opacity: 1;
              }
            }
            >.link{
              position: relative;
              >.box{
                position: absolute;
                width: 100px;
                background: #fff;
                top: 20px;
                left: 50%;
                transform: translate(-50%,0);
              }
            }
          }
          >.user{
            margin-left: 25px;
            >.btn{
              background: #4a90e2;
              border:1px solid #4a90e2;
              color: #fff;
              cursor: pointer;
              transition: .35s ease-in-out;
              padding: 8px 26px;
              border-radius: 5px;
              font-weight: 700;
              font-size: 14px;
            }
            >.btn:hover{
              background: #609de6;
              transform: translate3d(0, -2px, 0);
            }
          }
        }
      }
    }
    >.body{
      >.top{
        position: relative;
        z-index: -1;
        >img{
          width: 100%;
          position: fixed;
          z-index: 10;
          top: 0;
        }
        >p{
          position: relative;;
          z-index: 99;
          width: 100%;
          height: 70vh;
          top: 0;
          left: 0;
          background: rgba($color: #000000, $alpha: 0.5);
          color: #fff;
          font-size: 44px;
          text-align: center;
          line-height: 70vh;
        }
      }
      >.bottom{
        background: #252525;
        position: relative;
        z-index: 90;
        color: #fff;
        >.container{
          box-sizing: border-box;
          max-width: 1170px;
          margin: 0 auto;
          padding: 4.95em 0;
          font-size: 15px;
          min-height: calc(100vh - 300px);
          >ul,>p{
            font-size: 1.35714286em;
            line-height: 1.68421053em;
            font-weight: 400;
            opacity: .8;
          }
          >p{
            margin-bottom: 1.36842105263158em;
            >a{
              color: #fff;
              font-size: 1em;
              text-decoration: underline;
              font-weight: 400;
              line-height: 1.85714286em;
            }
          }
        }
      }
    }
    >.footer{
      background: #1b1b1b;
      display: flex;
      flex-direction: column;
      line-height: 25px;
      padding: 3.71em 0;
      position: relative;
      z-index: 150;
      a{
        color: #fff;
        text-decoration: none;
        font-size: 12px;
      }
      >.container{
        opacity: .8;
        >.webside{
          display: flex;
        }
        >.info{
          color: #fff;
          opacity: .5;
          display: flex;
          max-width: 1170px;
          margin: 0 auto;
          justify-content: space-between;
          .copyright {
            margin-right: 12px;
            font-size: 12px;
            line-height: 27px;
          }
          >.contant{
            display: flex;
            width: 50%;
            flex-wrap: wrap;
            >.link >a{
              margin: 0 10px;
            }
          }
          >.ba{}
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    #pageMode {
      >.body {
        >.bottom {
          >.container {
            padding: 40px 20px;
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 960px) {
    #pageMode{
      >.head{
        position: relative;
        background: #fff;
        >.container{
          >.control{
            >.tag{
              color: #252525;
            }
          }
        }
      }
      >.body{
        >.top{
          >p{
            height: 150px;
            line-height: 150px;
            font-size: 30px;
          }
        }
      }
      .container{
        padding: 0 12px;
        font-size: 13px !important;
      }
    }
  }
  @media screen and (max-width: 770px) {
    #pageMode{
      .container{
        max-width: calc(100vw - 24px);
        padding: 12px;
      }
      >.footer{
        >.container{
          >.webside , >.info{
            align-items: center;
            flex-direction: column;
          }
          >.info >.contant{
            width: 100%;
            justify-content: center;
            >.link >a{
              margin: 0;
              margin-left:10px;
            }
          }
        }
      }
    }
  }
</style>
